import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "redux/store";
import { toast } from "react-toastify";
import ImageService from "../../../../services/image";

interface IImage {
  _id: string;
  url: string;
  crop: string;
  category: string;
  lineSpacing: number;
  population: number;
}

interface IAnnotate {
  total?: number;
  image?: IImage | null;
}

interface AnnotateState {
  values: unknown | null;
  isLoading: boolean;
  error: string | null;
  annotateData: IAnnotate | null;
}

const initialState: AnnotateState = {
  values: null,
  isLoading: true,
  error: null,
  annotateData: null
};

export const annotateSlice = createSlice({
  name: "annotate",
  initialState,
  reducers: {
    addAnnotation: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    addAnnotationSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    addAnnotationError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    getImageToAnnotate: (state, action: PayloadAction<IAnnotate>) => {
      state.isLoading = false;
      state.annotateData = action.payload;
    }
  }
});

export const {
  addAnnotation,
  addAnnotationSuccess,
  addAnnotationError,
  getImageToAnnotate
} = annotateSlice.actions;

export default annotateSlice.reducer;

export const fetchAddAnnotation =
  (data: unknown): AppThunk =>
  async (dispatch) => {
    try {
      await ImageService.addAnnotation(data),
        toast.success("Dados gravados com sucesso!");
      dispatch(addAnnotationSuccess());
    } catch (error) {
      let message: string;
      if (error instanceof Error) message = error.message;
      else message = String(error);

      toast.error(message);
      dispatch(addAnnotationError(message));
    }
  };

export const fetchImageToAnnotate =
  (cropType: string): AppThunk =>
  async (dispatch) => {
    try {
      const result = await ImageService.getImageToAnnotate(cropType);
      if (result.image) toast.info("Nova imagem para carregada!");
      else toast.info("Nenhuma imagem disponível para anotação!");
      dispatch(getImageToAnnotate(result));
    } catch (error) {
      let message: string;
      if (error instanceof Error) message = error.message;
      else message = String(error);

      toast.error(message);
      dispatch(addAnnotationError(message));
    }
  };
