import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";

interface IPosition {
  x: number;
  y: number;
}

interface IDrawer {
  image: string | undefined;
  divider?: number | undefined;
  multiplier?: number | undefined;

  getMeasurements?: (value: number) => void;
}
export const Drawer: React.FC<IDrawer> = ({
  image,
  divider,
  multiplier,
  getMeasurements
}) => {
  const lineCanvas = useRef<HTMLCanvasElement | null>(null);
  const canvasContainerRef = useRef<HTMLImageElement | null>(null);
  const [canvasContainerSize, setCanvasContainerSize] = useState({
    width: 0,
    height: 0
  });
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [start, setStart] = useState({ x: 0, y: 0 });
  const [end, setEnd] = useState({ x: 0, y: 0 });
  const [distance, setDistance] = useState(0);

  const convertPixelToCm = (value: number) => {
    if (divider === undefined && multiplier === undefined) return value;

    const valueA = divider ?? 0;
    const valueB = multiplier ?? 0;
    const valueC = value;
    const calculate = valueC * valueB;
    return calculate / valueA;
  };

  useEffect(() => {
    drawLine(start, end);
  }, [start, end]);

  // Update 'width' and 'height' when the window resizes
  useEffect(() => {
    window.addEventListener("resize", setCanvasSize);
  }, []);

  // This function calculates width and height of the canvas
  const setCanvasSize = () => {
    if (canvasContainerRef && canvasContainerRef.current) {
      const height = canvasContainerRef.current.clientHeight;
      const width = canvasContainerRef.current.clientWidth;

      setCanvasContainerSize({ width, height });

      //resize canvas
      if (lineCanvas && lineCanvas.current) {
        lineCanvas.current.width = width;
        lineCanvas.current.height = height;
      }
    }
  };

  const drawLabel = (
    ctx: CanvasRenderingContext2D,
    text: string,
    p1: IPosition,
    p2: IPosition,
    alignment: string,
    padding: number
  ) => {
    if (!alignment) alignment = "center";
    if (!padding) padding = 0;

    ctx.font = "9pt Arial";

    let dx = p2.x - p1.x;
    let dy = p2.y - p1.y;
    const len = Math.sqrt(dx * dx + dy * dy);

    // Keep text upright
    let angle = Math.atan2(dy, dx);
    if (angle < -Math.PI / 2 || angle > Math.PI / 2) {
      const p = p1;
      p1 = p2;
      p2 = p;
      dx *= -1;
      dy *= -1;
      angle -= Math.PI;
    }

    let p, pad;
    if (alignment == "center") {
      p = p1;
      pad = 1 / 2;
    } else {
      const left = alignment == "left";
      p = left ? p1 : p2;
      pad = (padding / len) * (left ? 1 : -1);
    }
    ctx.save();

    const width = ctx.measureText(text).width * 1.4;

    ctx.textAlign = alignment as CanvasTextAlign;
    ctx.fillStyle = "rgba(0, 0, 0, 0.6)";
    ctx.translate(p.x + dx * pad, p.y + dy * pad);
    ctx.rotate(angle);
    ctx.fillRect(-30, -30, width, parseInt(ctx.font, 22));

    ctx.fillStyle = "white";
    ctx.fillText(text, 0, -10);
    ctx.restore();
  };

  const drawLine = (p1: IPosition, p2: IPosition) => {
    if (!lineCanvas.current) {
      return;
    }
    const canvas = lineCanvas.current;

    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.textBaseline = "bottom";
      ctx.lineWidth = 4;

      ctx.strokeStyle = "red";

      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.beginPath();
      ctx.stroke();
      ctx.moveTo(p1.x, p1.y);
      ctx.lineTo(p2.x, p2.y);
      ctx.stroke();

      ctx.beginPath();
      ctx.arc(p1.x, p1.y, 2, 0, Math.PI * 2, false);
      ctx.fill();
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(p2.x, p2.y, 2, 0, Math.PI * 2, false);
      ctx.fill();
      ctx.stroke();

      //calculate distance in PX
      const a = start.x - end.x;
      const b = start.y - end.y;

      const distanceInPx = Math.sqrt(a * a + b * b);
      setDistance(distanceInPx);

      let label = "";

      //if exists factor to convert, do the convertion
      if (divider !== undefined && multiplier !== undefined) {
        const distanceInCm = convertPixelToCm(distanceInPx);
        label = `${distanceInCm.toFixed(2)} cm`;
      } else label = `${distanceInPx.toFixed(2)} px`;

      drawLabel(ctx, label, p1, p2, "center", 1);
    }
  };

  const mousedown = (e: React.MouseEvent) => {
    setIsDrawing(true);
    setStart({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
    setEnd({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
  };

  const mousemove = (e: React.MouseEvent) => {
    if (!isDrawing) return;

    setEnd({
      x: e.nativeEvent.offsetX,
      y: e.nativeEvent.offsetY
    });
  };

  const mouseup = () => {
    setIsDrawing(false);
    if (getMeasurements) getMeasurements(convertPixelToCm(distance));
  };

  return (
    <Box>
      <Box
        sx={{
          width: "500px",
          height: "500px"
        }}
      >
        <img
          ref={canvasContainerRef}
          onLoad={setCanvasSize}
          src={image}
          style={{
            width: "100%",
            height: "100%",
            float: "left"
          }}
        />

        <canvas
          ref={lineCanvas}
          onMouseDown={mousedown}
          onMouseMove={mousemove}
          onMouseUp={mouseup}
          style={{
            position: "relative",
            top: "-" + canvasContainerSize.width + "px",
            left: 0
          }}
        />
      </Box>
    </Box>
  );
};

export default Drawer;
