const listItemText = {
  MuiListItemText: {
    styleOverrides: {
      root: {
        marginTop: 0,
        marginBottom: 0,
        marginLeft: 5
      }
    }
  }
};

export default listItemText;
