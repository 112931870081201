import { useEffect, DependencyList } from "react";

export function useDebounceEffect(
  fn: (...value: DependencyList) => void,
  waitTime: number,
  deps: DependencyList
) {
  useEffect(() => {
    const t = setTimeout(() => {
      return fn(deps);
    }, waitTime);

    return () => {
      clearTimeout(t);
    };
  }, deps);
}
