import React from "react";
import GoogleMapReact from "google-map-react";
import Box from "@mui/material/Box";
import Marker from "../../components/Marker";
import { IFile } from "../../services/image";

interface IMaps {
  files: IFile[];
}

const Maps: React.FC<IMaps> = ({ files }) => {
  const defaultProps = {
    center: {
      lat: -23.5475, //SAO PAULO
      lng: -46.63611
    },
    zoom: 7
  };
  const getMapBounds = (map, maps, files) => {
    const bounds = new maps.LatLngBounds();
    files.forEach((file) => {
      if (file.latitude !== 0 && file.longitude !== 0)
        bounds.extend(new maps.LatLng(file.latitude, file.longitude));
    });
    return bounds;
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, files) => {
    if (files.lengh > 0) {
      // Get bounds by our places
      const bounds = getMapBounds(map, maps, files);
      // Fit map to bounds
      map.fitBounds(bounds);
      // Bind the resize listener
      bindResizeListener(map, maps, bounds);
    }
  };

  return (
    <Box style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyB2KJfUkBTtIzDzkUY5wIgy7VEJjBv5S8M" // MUDAR KEY
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, files)}
      >
        {files.map((file) => {
          if (file.latitude !== 0 && file.longitude !== 0)
            return (
              <Marker
                key={file.id}
                lat={file.latitude}
                lng={file.longitude}
                file={file}
              />
            );
        })}
      </GoogleMapReact>
    </Box>
  );
};

export default Maps;
