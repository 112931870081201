import { DropContainer, UploadMessage } from "./styles";

import React, { ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useFiles } from "../../context/files";

const Upload: React.FC = () => {
  const { handleUpload } = useFiles();

  const onDropAccepted = useCallback(
    (files: File[]) => {
      handleUpload(files);
    },
    [handleUpload]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    accept: ["image/jpeg", "image/jpg", "image/png"],
    maxSize: 10485760,
    maxFiles: 50,
    onDropAccepted
  });

  const renderDragMessage = useCallback((): ReactNode => {
    const isFileTooLarge =
      fileRejections.length > 0 && fileRejections[0].file.size > 10485760;

    if (!isDragActive) {
      return (
        <UploadMessage>Arraste imagens aqui...(no máximo 50)</UploadMessage>
      );
    }

    if (isDragReject && isFileTooLarge) {
      return (
        <UploadMessage type="error">
          Selecione arquivos com até 10mb!
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte as imagens aqui</UploadMessage>;
  }, [isDragActive, isDragReject, fileRejections]);
  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
};

export default Upload;
