import { Edit, Check } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from "@mui/material";
import { ICheckListInferences } from "features/Images/Inference/Check/types";
import { useEffect, useState } from "react";
import { useSWRConfig } from "swr";
import image from "services/image";
import config from "config";

interface IDialogHeader {
  children?: React.ReactNode;
  data: ICheckListInferences;
}

const DialogHeader: React.FC<IDialogHeader> = ({ data }) => {
  const perc_falhas_m = data.perc_falhas_manual
    ? data.perc_falhas_manual.toFixed(2)
    : 0;
  const perc_falhas_i = data.perc_falhas_inf
    ? data.perc_falhas_inf.toFixed(2)
    : 0;

  const perc_replicatas_m = data.perc_replicatas_manual
    ? data.perc_replicatas_manual.toFixed(2)
    : 0;
  const perc_replicatas_i = data.perc_replicatas_inf
    ? data.perc_replicatas_inf.toFixed(2)
    : 0;

  const perc_atrasadas_i = data.perc_atrasadas_inf
    ? data.perc_atrasadas_inf.toFixed(2)
    : 0;

  const [isFieldFocused, setFieldFocused] = useState(false);
  const [isUpdated, setUpdated] = useState(false);
  const [noAtrasadas, setNoAtrasadas] = useState(0);
  const [percentualAtrasadas, setPercentualAtrasadas] = useState("");
  const { mutate } = useSWRConfig();

  useEffect(() => {
    setNoAtrasadas(data.no_atrasadas);
    const percentualAtrasadas = data.perc_atrasadas_manual
      ? data.perc_atrasadas_manual.toFixed(2)
      : "0";
    setPercentualAtrasadas(percentualAtrasadas);
  }, [data, setNoAtrasadas, setPercentualAtrasadas]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    //key code for enter
    if (event.key === "Enter") {
      event.preventDefault();
      handleFocus(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let newValue;

    if (value !== "") newValue = value.replace(/[^0-9]/g, "");
    else newValue = 0;

    setNoAtrasadas(parseInt(newValue));
  };

  const handleFocus = async (focused: boolean) => {
    setFieldFocused(focused);

    if (!focused) {
      const peat = noAtrasadas / data.plantas_observadas;
      const neat = noAtrasadas;

      setPercentualAtrasadas((peat * 100).toFixed(2));

      try {
        await image.updateAnnotation(data.id_anotacao, {
          peat,
          neat
        });
        setUpdated(true);
        mutate(`${config.GAIA_API.URL}annotations_inferences`);
      } catch {
        setUpdated(false);
      }
    }
  };

  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container rowSpacing={4}>
          <Grid item xs={3}>
            <Typography variant="caption">Produtor</Typography>
            <Typography variant="h6">{data.produtor}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">Propriedade</Typography>
            <Typography variant="h6">{data.propriedade}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">Talhão</Typography>
            <Typography variant="h6">{data.talhao}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="caption">Cultura</Typography>
            <Typography variant="h6">{data.cultura}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="caption">N. Estimada (ne)</Typography>
            <Typography variant="h6">{data.plantas_estimadas}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="caption">N. Observada (no)</Typography>
            <Typography variant="h6">{data.plantas_observadas}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="caption">População</Typography>
            <Typography variant="h6">{data.populacao}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">Falhas: {data.no_falhas}</Typography>
            <Typography variant="body1">
              Manual: {perc_falhas_m}% / Inferência:
              {perc_falhas_i}%
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h6">
              Replicatas: {data.no_replicatas}
            </Typography>
            <Typography variant="body1">
              Manual: {perc_replicatas_m}% / Inferência:
              {perc_replicatas_i}%
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {!isFieldFocused ? (
              <Box onClick={() => handleFocus(true)}>
                <Typography sx={{ display: "inline-flex" }} variant="h6">
                  Atrasadas: {noAtrasadas}
                </Typography>

                {isUpdated ? (
                  <Check
                    sx={{
                      color: "green",
                      position: "relative",
                      top: "3px",
                      left: "5px"
                    }}
                  />
                ) : (
                  <Edit
                    sx={{ position: "relative", top: "3px", left: "5px" }}
                  />
                )}
              </Box>
            ) : (
              <>
                <Typography sx={{ display: "inline-flex" }} variant="h6">
                  Atrasadas:
                </Typography>
                <TextField
                  sx={{
                    width: "60px",
                    height: "30px",
                    display: "flex-row"
                  }}
                  inputProps={{
                    style: {
                      padding: "5px"
                    }
                  }}
                  size="small"
                  autoFocus
                  value={noAtrasadas}
                  onKeyUp={handleKeyUp}
                  onChange={handleChange}
                  onBlur={() => handleFocus(false)}
                />
              </>
            )}
            <Typography variant="body1">
              Manual: {percentualAtrasadas}% / Inferência:
              {perc_atrasadas_i}%
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DialogHeader;
