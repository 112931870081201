const list = {
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0
      }
    }
  }
};

export default list;
