import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { FormikValues, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { RootState } from "redux/reducers";

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Paper,
  Select,
  Tooltip,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Layout from "components/Layout";

import Cropper, { ICropData } from "components/Cropper";
import Measure from "components/Measure";

import {
  fetchAddAnnotation,
  fetchImageToAnnotate
} from "features/Images/Annotation/Viewer/annotateSlice";

import { useAuth0 } from "@auth0/auth0-react";
import { DeleteForever } from "@mui/icons-material";
import InputField from "components/Form/InputField";
import MultiStepForm, { FormStep } from "components/Form/MultiStepForm";

const useStyles = makeStyles((theme: Theme) => ({
  containerStyle: {
    marginTop: theme.spacing(1)
  },
  paperStyle: {
    padding: theme.spacing(4),
    width: theme.spacing(150)
  }
}));

export const ViewAnnotationForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { annotateData, error, isLoading } = useSelector(
    (state: RootState) => state.Annotate
  );

  const image = annotateData?.image;
  const [cropType, setCropType] = useState("");
  const { user } = useAuth0();

  const initialValues = {
    distanceInPixel: "",
    ne: "",
    no: "",
    nf: "",
    nr: "",
    neat: ""
  };

  useEffect(() => {
    dispatch(fetchImageToAnnotate(cropType));
  }, [cropType]);

  const [measurementsInPixels, setMeasurementInPixels] = useState<number>(0);

  const [cropData, setCropData] = useState<ICropData>();

  const handleSubmit = async (values: FormikValues, { resetForm }) => {
    if (image) {
      const cropCoordinates = {
        x: cropData?.x,
        y: cropData?.y,
        width: cropData?.width,
        height: cropData?.height
      };
      const payload = {
        image: image._id,
        user: user?.email,
        cropCoordinates,
        ...values
      };

      //api request
      dispatch(fetchAddAnnotation(payload));

      if (!error) {
        //reset datas
        resetForm({
          values: initialValues
        });

        setMeasurementInPixels(0);

        setCropData({ x: 0, y: 0, width: 0, height: 0, image: "" });

        //get new image to annotate
        dispatch(fetchImageToAnnotate(cropType));
      }
    }
  };

  return (
    <Layout>
      <Container className={classes.containerStyle}>
        <ToastContainer
          position="top-center"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <FormCropSelection setCropType={setCropType} cropType={cropType} />
        <br />
        <Paper className={classes.paperStyle}>
          {!image && isLoading && (
            <Typography
              component="h1"
              align="center"
              variant="h5"
              style={{ marginTop: 20 }}
            >
              Carregando imagem para anotação
            </Typography>
          )}
          {!image && !isLoading && (
            <Typography
              component="h1"
              align="center"
              variant="h5"
              style={{ marginTop: 20 }}
            >
              Nenhuma imagem disponível para anotação!
            </Typography>
          )}
          {image && !isLoading && (
            <MultiStepForm
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <FormStep stepName="Recortar">
                <FormContentCrop
                  key="step1"
                  url={image.url}
                  cropType={cropType}
                  callBackGetCropData={setCropData}
                  callBackGetMeasurementInPixels={setMeasurementInPixels}
                />
              </FormStep>
              <FormStep stepName="Anotar">
                <FormContentNote
                  key="step2"
                  lineSpacing={image.lineSpacing}
                  image={cropData?.image}
                  population={image.population}
                  measurementsInPixels={measurementsInPixels}
                />
              </FormStep>
            </MultiStepForm>
          )}
        </Paper>
      </Container>
    </Layout>
  );
};

const FormCropSelection = ({ setCropType, cropType }) => {
  const handleChange = (event: { target: { value: unknown } }) => {
    setCropType(event.target.value);
  };

  return (
    <Box>
      <FormControl required variant="filled">
        <InputLabel id="label-crop">Cultura</InputLabel>
        <Select
          native
          required
          labelId="label-crop"
          id="crop"
          value={cropType}
          onChange={handleChange}
          style={{ width: 200 }}
        >
          <option aria-label="None" value="">
            Todos
          </option>
          <option value={"corn"}>Milho</option>
          <option value={"soy"}>Soja</option>
        </Select>
      </FormControl>
    </Box>
  );
};

const FormContentCrop = ({
  url,
  cropType,
  callBackGetCropData,
  callBackGetMeasurementInPixels
}) => {
  const [measurementsInPixels, setMeasurementInPixels] = useState<number[]>([]);

  const [cropData, setCropData] = useState<ICropData>();
  const { setFieldTouched, setFieldValue } = useFormikContext();

  useEffect(() => {
    callBackGetCropData(cropData);
  }, [cropData]);

  const getMeasurementsInPixel = (value: number) => {
    if (value > 0)
      setMeasurementInPixels((prevValues) => [...prevValues, value]);
  };

  useEffect(() => {
    if (measurementsInPixels.length > 0) {
      const measurementsInPixelsAVG = _.mean(measurementsInPixels);
      callBackGetMeasurementInPixels(measurementsInPixelsAVG);
      setFieldValue(
        "distanceInPixel",
        measurementsInPixelsAVG.toFixed(2),
        true
      );
      setFieldTouched("distanceInPixel", true, true);
    }
  }, [measurementsInPixels]);

  const handleClearMeasurements = () => {
    setFieldValue("distanceInPixel", 0, true);
    setMeasurementInPixels([]);
    setFieldTouched("distanceInPixel", true, true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "650px",
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
      }}
    >
      <Box sx={{ width: "500px" }}>
        <Typography sx={{ marginBottom: "10px" }}>
          Selecione a área que será anotada
        </Typography>
        <Cropper
          url={`${url}?not-from-cache`}
          cropType={cropType}
          getCropData={setCropData}
        />
        <Box>
          {measurementsInPixels.length > 0 && (
            <Typography>Linhas traçadas:</Typography>
          )}
          {_.map(measurementsInPixels, (val) => (
            <Typography variant="body1">{val.toFixed(2)}</Typography>
          ))}
        </Box>
      </Box>
      <Box sx={{ justifySelf: "right" }}>
        <Typography sx={{ marginBottom: "10px" }}>
          Selecione as áreas equivalente ao espaçamento entre as linhas
        </Typography>
        <Measure
          getMeasurements={getMeasurementsInPixel}
          image={cropData?.image}
        />
        <InputField
          required
          type="number"
          helperText="Arraste o cursor e meça a distância entre as linhas"
          name="distanceInPixel"
          label="Qual a distância em pixels do espaçamento entre as linhas?"
        />
        <Button
          type="reset"
          variant="contained"
          disabled={measurementsInPixels.length === 0}
          onClick={handleClearMeasurements}
        >
          LIMPAR LINHAS
        </Button>
      </Box>
    </Box>
  );
};

type ContagemType = "plantas" | "falhas";

const FormContentNote = ({
  image,
  lineSpacing,
  population,
  measurementsInPixels
}) => {
  const [measurements, setMeasurements] = useState<number[]>([]);
  const [calculatedPopulation, setCalculatedPopulation] = useState<number>(0);
  const [toggleContagem, setToggleContagem] = useState<ContagemType>("plantas");
  const [fieldToUpdate, setFieldToUpdate] = useState<"ne" | "nf">("ne");
  const { setFieldTouched, setFieldValue } = useFormikContext();

  const getMeasurements = (value: number) => {
    if (value > 0) setMeasurements((prevValues) => [...prevValues, value]);
  };

  const removeMeasurement = (index: number) => {
    setMeasurements(measurements.filter((_, i) => i !== index));
  };

  const calculatePlantPopulation = () => {
    let result = 0;

    if (measurements && measurements.length > 0) {
      const totalMeasurement = Math.ceil(_.sum(measurements));

      const valueA = population / (10000 / (lineSpacing / 100));
      const valueB = totalMeasurement / 100;
      result = valueA * valueB;
    }

    setCalculatedPopulation(Math.ceil(result));
  };

  useEffect(() => {
    calculatePlantPopulation();
  }, [measurements]);

  useEffect(() => {
    if (calculatedPopulation) {
      setFieldValue(fieldToUpdate, calculatedPopulation, true);
      setFieldTouched(fieldToUpdate, true, true);
    } else {
      setFieldValue(fieldToUpdate, "", false);
      setFieldTouched(fieldToUpdate, false, false);
    }
  }, [calculatedPopulation, fieldToUpdate]);

  const ButtonStartAction = useCallback(
    ({ contagemType }: { contagemType: ContagemType }) => {
      return (
        <Button
          variant="contained"
          disabled={contagemType === toggleContagem}
          color={contagemType === "falhas" ? "info" : "primary"}
          onClick={() => {
            setToggleContagem(contagemType);
            setFieldToUpdate(contagemType === "plantas" ? "ne" : "nf");
            setMeasurements([]);
          }}
        >
          INICIAR CONTAGEM {contagemType}
        </Button>
      );
    },
    [toggleContagem]
  );

  return (
    <Box>
      <Typography component="h1" variant="h6" style={{ marginBottom: "10px" }}>
        Baseado na imagem responda o questionário:
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr"
        }}
      >
        <Box>
          <Grid container mb={2} sx={{ width: "500px" }}>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ textAlign: "center" }}>
                <ButtonStartAction contagemType="plantas" />
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={0} sx={{ textAlign: "center" }}>
                <ButtonStartAction contagemType="falhas" />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ textAlign: "center", mt: 2 }}>
                <Typography variant="h5">{`Traçar as ${toggleContagem}`}</Typography>
              </Paper>
            </Grid>
          </Grid>

          <Measure
            getMeasurements={getMeasurements}
            image={image}
            divider={measurementsInPixels}
            multiplier={lineSpacing}
          />
          {!measurements || measurements.length == 0 ? (
            <Typography
              component="h1"
              variant="h6"
              align="center"
              style={{ marginTop: 20 }}
            >
              Nenhuma medição realizada
            </Typography>
          ) : (
            <Box>
              <Typography component="h1" variant="h6" style={{ marginTop: 20 }}>
                Medidas realizadas para {toggleContagem}:
              </Typography>
              <List
                sx={{
                  width: "500px",
                  maxHeight: "150px",
                  overflowY: "scroll"
                }}
              >
                {measurements.map((value, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <Tooltip title="Remover" placement="right" arrow>
                        <IconButton
                          sx={{ width: "20px", height: "20px" }}
                          edge="end"
                          aria-label="Remover"
                          onClick={() => removeMeasurement(index)}
                        >
                          <DeleteForever />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        variant: "body2"
                      }}
                      primary={`${value.toFixed(2)} cm`}
                    />
                  </ListItem>
                ))}
              </List>

              <Typography
                component="h1"
                variant="h6"
                style={{ marginTop: "10px" }}
              >
                Total medido:
                {` ${_.sum(measurements).toFixed(2)} cm`}
              </Typography>
            </Box>
          )}
        </Box>
        <Box>
          <InputField
            required
            type="number"
            label="Quantas plantas esperaríamos ver?"
            helperText="Faça a medição da imagem"
            name="ne"
          />
          <br />
          <br />
          <InputField
            required
            type="number"
            label="Quantas plantas você consegue contar nessa foto? "
            helperText="Desconsidere plantas cuja base esteja fora da fotografia, ou seja, aquelas que apenas algumas folhas podem ser vistas."
            name="no"
          />
          <br />
          <br />
          <InputField
            required
            fullWidth
            type="number"
            label="Quantas falhas há na foto?"
            helperText="Falhas são locais onde é possível observar que falta uma planta. Cada planta ausente conta como uma (1) falha."
            name="nf"
          />
          <br />
          <br />
          <InputField
            required
            fullWidth
            type="number"
            label="Quantas duplicas vc consegue observar em toda a foto?"
            helperText="Duplicatas é uma planta que divide o mesmo local com outra. Exemplo: Se três plantas nascem no mesmo local, duas deverão ser contadas como duplicatas."
            name="nr"
          />
          <br />
          <br />
          <InputField
            required
            type="number"
            label="Quantas plantas atrasadas em relação a maioria?"
            helperText="Comparando o estádio de desenvolvimento entre as plantas da foto, você diria que há plantas atrasadas em relação a maioria? Se sim, quantas?"
            name="neat"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ViewAnnotationForm;
