import React, { useState, useRef, useCallback } from "react";

import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { Box } from "@mui/material";
import FsLightbox from "fslightbox-react";
import { useDebounceEffect } from "./useDebounceEffect";
import { imgPreview } from "./imgPreview";

interface ICropper {
  url: string;
  cropType: string;

  getCropData(values: ICropData): void;
}
export interface ICropData {
  x: number;
  y: number;
  width: number;
  height: number;
  image: string;
}

export const Cropper: React.FC<ICropper> = ({ url, cropType, getCropData }) => {
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const cropArea = cropType === "soy" ? 75 : 150;

  const [crop, setCrop] = useState<Crop>();

  const [toggler, setToggler] = useState<boolean>(false);

  const randomNumber = (min: number, max: number) => {
    const value = Math.random() * (max - min) + min;

    return value;
  };

  const onLoad = useCallback((e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop({
      unit: "px",
      x: randomNumber(0, width - cropArea),
      y: randomNumber(0, height - cropArea),
      width: cropArea,
      height: cropArea
    });
  }, []);

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imageRef.current) {
        imgPreview(imageRef.current, completedCrop, 1, 0).then((img) =>
          getCropData({
            x: completedCrop.x,
            y: completedCrop.y,
            width: completedCrop.width,
            height: completedCrop.height,
            image: img
          })
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <Box>
      <ReactCrop
        minWidth={150}
        minHeight={150}
        ruleOfThirds
        locked
        crop={crop}
        onChange={() => null}
        onComplete={(c) => setCompletedCrop(c)}
      >
        <img
          alt="Crop me"
          ref={imageRef}
          src={url}
          onLoad={onLoad}
          crossOrigin="anonymous"
        />
      </ReactCrop>

      <Box
        sx={{
          cursor: "pointer",
          float: "right",
          position: "relative",
          width: "40px",
          height: "40px",
          marginTop: "-45px",
          zIndex: 2000
        }}
      >
        <ZoomInIcon
          aria-label="Zoom"
          titleAccess="Zoom"
          onClick={() => setToggler(!toggler)}
          sx={{ color: "white", fontSize: 40 }}
        />
        <FsLightbox sources={[url]} toggler={toggler} type="image" />
      </Box>
    </Box>
  );
};

export default Cropper;
