import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";

const LogoutButton: React.FC = () => {
  const { logout } = useAuth0();

  return (
    <Button color="inherit" onClick={() => logout()}>
      LOGOUT
    </Button>
  );
};

export default LogoutButton;
