function listItem(theme) {
  return {
    defaultProps: {
      disableGutters: true
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: 3,
          paddingBottom: 3,
          marginBottom: "1px",
          backgroundColor: "rgba(0, 0, 0, 0.06)",
          borderRadius: "2px",
          borderBottom: " 1px solid rgba(0, 0, 0, 0.42)",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.09)",
            borderBottom: `1px solid ${theme.palette.primary.main.toString()}`,
            "& .MuiListItemIcon-root": {
              color: "white"
            }
          }
        }
      }
    }
  };
}

export default listItem;
