import { useAuth0 } from "@auth0/auth0-react";
import Router from "routes/router";

function App(): JSX.Element {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div className="page-layout">loading....</div>;
  }

  return <Router />;
}

export default App;
