import config from "config";
import _ from "lodash";
import { ApiManager, OptionsInterface } from "./apiManager";

export interface IFile {
  id: string;
  name: string;
  size: string;
  uploaded?: boolean;
  preview: string;
  file: File | null;
  progress?: number;
  error?: Error;
  url: string;
  longitude: number;
  latitude: number;
  producer: string;
  property?: string;
  hasAnnotation?: boolean;
}

class ImageService {
  async uploadImagestoAnnotate(
    file: FormData,
    onUploadProgress: (progressEvent: ProgressEvent) => void
  ) {
    const options: OptionsInterface = {
      method: "POST",
      data: file,
      onUploadProgress: onUploadProgress
    };

    const result = await this.upload("annotate/upload", options);
    return result;
  }

  async upload(endpoint: string, options: OptionsInterface) {
    const Api = new ApiManager(config.GAIA_API.URL + endpoint, options);

    try {
      const result = await Api.request();
      return result.data;
    } catch (err) {
      let message = "";
      const httpStatusCode: number = _.get(err, "request.status", 0);

      switch (httpStatusCode) {
        case 413:
          message = "Arquivo maior que 10 mb";
          break;
        case 429:
          message = "Muitos uploads simultâneos";
          break;
        default:
          message = _.get(err, "message", "");
      }

      throw message;
    }
  }

  async remove(id: string) {
    const options: OptionsInterface = {
      method: "DELETE"
    };

    const Api = new ApiManager(
      config.GAIA_API.URL + `annotate/image/${id}`,
      options
    );

    const result = await Api.request();
    return result;
  }

  async get(user: string) {
    const options: OptionsInterface = {
      method: "GET",
      params: { user }
    };
    const Api = new ApiManager(config.GAIA_API.URL + `annotate`, options);

    const result = await Api.request();
    return result;
  }

  async getImageToAnnotate(cropType: string) {
    const options: OptionsInterface = {
      method: "GET",
      params: { cropType }
    };
    const Api = new ApiManager(config.GAIA_API.URL + `annotate/image`, options);

    const result = await Api.request();
    if (result && result.data)
      return {
        total: _.get(result.data, "total", 0),
        image: _.get(result.data, "image", null)
      };
    else return {};
  }

  async addAnnotation(payload: unknown) {
    const options: OptionsInterface = {
      method: "POST",
      data: payload
    };

    const Api = new ApiManager(config.GAIA_API.URL + `annotate`, options);

    const result = await Api.request();
    return result;
  }

  async updateAnnotation(id: string, payload: unknown) {
    const options: OptionsInterface = {
      method: "POST",
      data: payload
    };

    const Api = new ApiManager(config.GAIA_API.URL + `annotate/${id}`, options);

    const result = await Api.request();
    return result;
  }
}

export default new ImageService();
