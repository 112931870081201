import React, { useState } from "react";
import {
  Formik,
  Form,
  FormikConfig,
  FormikValues,
  FormikHelpers
} from "formik";
import FormNavigation from "../FormNavigation";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

interface IProps extends FormikConfig<FormikValues> {
  children: React.ReactNode;
}

const MultiStepForm = ({ children, initialValues, onSubmit }: IProps) => {
  const [stepNumber, setStepNumber] = useState(0);
  const steps = React.Children.toArray(children) as React.ReactElement[];
  const [snapshot, setSnapshot] = useState(initialValues);

  const step = steps[stepNumber];
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;

  const next = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values: FormikValues) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  const handleSubmit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    if (step.props.onSubmit) {
      await step.props.onSubmit(values, actions);
    }
    if (isLastStep) {
      //reset wizard
      setStepNumber(0);
      //send values
      return onSubmit(values, actions);
    } else {
      actions.setTouched({});
      actions.setSubmitting(false);
      next(values);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={snapshot}
      onSubmit={handleSubmit}
      validationSchema={step.props.validationSchema}
    >
      {(formik) => (
        <Form>
          <Stepper activeStep={stepNumber}>
            {steps.map((currentStep) => {
              const label = currentStep.props.stepName;
              return (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box sx={{ padding: "20px 10px" }}>{step}</Box>
          <FormNavigation
            isSubmitting
            isLastStep={isLastStep}
            hasPrevious={stepNumber > 0}
            onBackClick={() => previous(formik.values)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default MultiStepForm;

interface IStep {
  stepName: string;
  children: React.ReactNode;
  onSubmit?: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>
  ) => void;
}
export const FormStep = ({ children }: IStep) => {
  return <>{children}</>;
};
