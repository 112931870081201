import { TextField, TextFieldProps } from "@mui/material";
import { FieldAttributes, useField } from "formik";

const InputField: React.FC<FieldAttributes<TextFieldProps>> = (props) => {
  const { label, placeholder, inputProps } = props;
  const [field, meta] = useField<TextFieldProps>(props);

  return (
    <>
      <TextField
        fullWidth
        label={label}
        placeholder={(placeholder ?? label) as string}
        inputProps={inputProps}
        variant="filled"
        {...field}
        {...props}
        error={meta.touched && Boolean(meta.error)}
      />
    </>
  );
};

export default InputField;
