import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { TransitionProps } from "@mui/material/transitions";
import { canvasPreview } from "components/Cropper/canvasPreview";
import { ICheckListInferences } from "features/Images/Inference/Check/types";
import * as React from "react";
import ReactCrop from "react-image-crop";
import DialogHeader from "./dialog_header";

interface IFullScreenDialog {
  url: string | undefined;
  open: boolean;
  handleClose: () => void;
  data: ICheckListInferences;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog: React.FC<IFullScreenDialog> = ({
  url,
  open,
  handleClose,
  data
}) => {
  const imgRef = React.useRef<HTMLImageElement>(null);
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const { cropCoordinates } = data;

  const onLoad = () => {
    if (
      cropCoordinates?.width &&
      cropCoordinates?.height &&
      imgRef.current &&
      previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(
        imgRef.current,
        previewCanvasRef.current,
        {
          height: cropCoordinates.height,
          width: cropCoordinates.width,
          unit: "px",
          x: cropCoordinates.x,
          y: cropCoordinates.y
        },
        1,
        0
      );
    }
  };

  const CropElement = () => {
    return (
      <ReactCrop
        minWidth={150}
        minHeight={150}
        crop={{ ...cropCoordinates, unit: "px" }}
        locked
        onChange={() => null}
      >
        <img
          alt="Crop me"
          src={url}
          crossOrigin="anonymous"
          width={"500px"}
          ref={imgRef}
          onLoad={onLoad}
        />
      </ReactCrop>
    );
  };

  const CropAreaElement = () => {
    return (
      <div>
        <canvas
          ref={previewCanvasRef}
          style={{
            width: 500,
            height: 500
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Box sx={{ flexGrow: 1, padding: 4 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={12}>
              <DialogHeader data={data} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ textAlign: "center" }}>
                <CropElement />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box sx={{ textAlign: "center" }}>
                <CropAreaElement />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </div>
  );
};

export default FullScreenDialog;
