import React from "react";
import Layout from "components/Layout";
import Typography from "@mui/material/Typography";

const Callback: React.FC = () => {
  return (
    <Layout>
      <Typography variant="h5" align="center"></Typography>
    </Layout>
  );
};

export default Callback;
