import React from "react";

import { Typography } from "@mui/material";
import axios from "axios";
import DataTableCheckInferences from "components/DataTableCheckInferences";
import Layout from "components/Layout";
import config from "config";
import useSWR from "swr";
import { ICheckListInferences } from "./types";
const fetcher = (url: string) => axios.get(url).then((res) => res.data);

const InferenceCheck: React.FC = () => {
  const { data, isLoading } = useSWR<Array<ICheckListInferences>>(
    `${config.GAIA_API.URL}annotations_inferences`,
    fetcher
  );

  if (isLoading) {
    return <div className="page-layout">loading....</div>;
  }

  if (data) {
    return (
      <Layout>
        <Typography component={"h2"}>Lista de Inferências</Typography>
        <DataTableCheckInferences rows={data} />
      </Layout>
    );
  }

  return null;
};

export default InferenceCheck;
