import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Menu from "./menu";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "components/LoginButton";
import LogoutButton from "components/LogoutButton";

const useStyles = makeStyles({
  itemStyle: {
    textAlign: "center"
  },
  itemStyleLogout: {
    textAlign: "right"
  }
});

const Header: React.FC = () => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const { user, isAuthenticated } = useAuth0();

  const toggleMenu =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpenMenu(open);
    };

  return (
    <>
      <AppBar position="fixed">
        <Paper square elevation={0}>
          <Toolbar>
            <Grid container alignItems="center">
              <Grid item xs={2} sm={1}>
                {isAuthenticated && (
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => setOpenMenu(true)}
                  >
                    <MenuIcon />
                  </IconButton>
                )}
              </Grid>
              <Grid item xs={8} sm={10} className={classes.itemStyle}>
                <Typography variant="h6">Projeto GAIA</Typography>
                {isAuthenticated && user ? (
                  <Typography>Bem-vindo, {user.name}</Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={2} sm={1} className={classes.itemStyleLogout}>
                {isAuthenticated ? <LogoutButton /> : <LoginButton />}
              </Grid>
            </Grid>
          </Toolbar>
        </Paper>
      </AppBar>
      <Toolbar />
      <Drawer open={openMenu} onClose={toggleMenu(false)}>
        <Menu />
      </Drawer>
    </>
  );
};

export default Header;
