import PropTypes from "prop-types";
import React, { useState } from "react";

import FileList from "components/FileList/index";
import Layout from "components/Layout";
import Upload from "components/Upload/index";
import { FileProvider, UploadType, useFiles } from "../../../../context/files";

import { InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import green from "@mui/material/colors/green";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import { Theme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { makeStyles } from "@mui/styles";
import InputField from "components/Form/InputField";
import Maps from "components/Maps";
import { Formik } from "formik";

const useStyles = makeStyles((theme: Theme) => ({
  containerStyle: {
    marginTop: theme.spacing(1)
  },
  paperStyle: {
    padding: theme.spacing(4),
    width: theme.spacing(150)
  },

  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 250
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={1}>{children}</Box>}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const FormContent = () => {
  const classes = useStyles();
  const { isLoadingFiles, filesToUpload, processFiles } = useFiles();

  return (
    <Formik
      initialValues={{
        producer: "",
        property: "",
        crop: "",
        plot: "",
        lineSpacing: "",
        category: "",
        population: ""
      }}
      onSubmit={(values) => {
        processFiles(values, UploadType.Annotation);
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <InputField
            required
            type="text"
            label="Qual o nome do produtor"
            helperText="Digite o nome do produtor da propriedade onde essas imagens foram capturadas"
            name="producer"
          />
          <br />
          <br />
          <InputField
            required
            type="text"
            label="Qual o nome da propriedade"
            helperText="Digite o nome da propriedade onde essas imagens foram capturadas"
            name="property"
          />
          <br />
          <br />
          <FormControl
            required
            variant="filled"
            className={classes.formControl}
          >
            <InputLabel id="label-crop">Cultura</InputLabel>
            <Select
              native
              required
              labelId="label-crop"
              id="crop"
              value={props.values.crop}
              onChange={(value) => props.handleChange(value)}
              style={{ width: 400 }}
            >
              <option aria-label="None" value="" />
              <option value={"corn"}>Milho</option>
              <option value={"soy"}>Soja</option>
              <option value={"cotton"}>Algodão</option>
            </Select>
          </FormControl>
          <br />
          <br />
          <FormControl
            required
            variant="filled"
            className={classes.formControl}
          >
            <InputLabel id="label-category">
              Estádio de desenvolvimento
            </InputLabel>
            <Select
              native
              required
              labelId="label-category"
              id="category"
              value={props.values.category}
              onChange={(value) => props.handleChange(value)}
              style={{ width: 400 }}
            >
              <option aria-label="None" value="" />
              <option value={"V0"}>V0</option>
              <option value={"V1"}>V1</option>
              <option value={"V2"}>V2</option>
              <option value={"V3"}>V3</option>
              <option value={"V4"}>V4</option>
              <option value={"V5"}>V5</option>
            </Select>
          </FormControl>
          <br />
          <br />
          <InputField
            required
            type="text"
            label="Qual o talhão"
            helperText="Digite o nome do talhão onde essas imagens foram capturadas"
            name="plot"
          />
          <br />
          <br />
          <InputField
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onWheel={(e: any) => e.target.blur()}
            required
            label="Qual o espaçamento entre linhas"
            name="lineSpacing"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">centímetros</InputAdornment>
              )
            }}
          />
          <br />
          <br />
          <InputField
            type="number"
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onWheel={(e: any) => e.target.blur()}
            required
            label="Qual a população semeada"
            name="population"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  população/hectare
                </InputAdornment>
              )
            }}
          />
          <br />
          <br />
          <Upload />
          <br />
          {isLoadingFiles && (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!isLoadingFiles && (
            <FileList files={filesToUpload} type="filesToUpload" />
          )}

          <br />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: 0 }}
          >
            Fazer upload
          </Button>
        </form>
      )}
    </Formik>
  );
};

const UploapPage: React.FC = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { uploadedFiles, getUploadedFiles } = useFiles();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setValue(newValue);
    if (newValue === 1) getUploadedFiles();
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Carregar novas imagens" />
        <Tab label="Imagens carregadas" />
        <Tab label="Locais" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Paper className={classes.paperStyle}>
          <FormContent />
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper className={classes.paperStyle}>
          <FileList files={uploadedFiles} type="uploadedFiles" />
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Paper className={classes.paperStyle}>
          <Maps files={uploadedFiles} />
        </Paper>
      </TabPanel>
    </>
  );
};

const FilesUpload: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout>
      <Container className={classes.containerStyle}>
        <FileProvider>
          <UploapPage />
        </FileProvider>
      </Container>
    </Layout>
  );
};

export default FilesUpload;
