import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import Header from "./header";

interface LayoutProps {
  children: React.ReactNode;
}

const GridContainer = styled(Grid)`
  padding: 10px;
`;

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      <Header />
      <GridContainer container direction="column" mt={5}>
        <Grid item lg={12}>
          {props.children}
        </Grid>
      </GridContainer>
    </>
  );
};

export default Layout;
