import { useAuth0 } from "@auth0/auth0-react";
import { sha1 } from "crypto-hash";
import exifr from "exifr";
import filesize from "filesize";
import { FormikValues } from "formik";
import _ from "lodash";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";
import { v4 as uuidv4 } from "uuid";
import UploadService, { IFile } from "../services/image";

export enum UploadType {
  Annotation
}

interface IFileContextData {
  isLoadingFiles: boolean;
  uploadedFiles: IFile[];
  filesToUpload: IFile[];
  handleUpload(files: File[]): void;
  processUpload(file: IFile, values: unknown, uploadType: UploadType): void;
  deleteFile(id: string): void;
  processFiles(values: unknown, uploadType: UploadType): void;
  getUploadedFiles(): void;
}

interface IResult {
  _id: string;
  name: string;
  size: number;
  url: string;
  latitude: number;
  longitude: number;
  producer: string;
  property: string;
  totalAnnotations: number;
}
interface IFileProviderProps {
  children: React.ReactNode;
}

const FileContext = createContext<IFileContextData>({} as IFileContextData);

const FileProvider: React.FC<IFileProviderProps> = ({ children }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [filesToUpload, setFilesToUpload] = useState<IFile[]>([]);
  const [isLoadingFiles, setLoadingFiles] = useState(false);

  const { user } = useAuth0();

  const email = user?.email as string;

  useEffect(() => {
    getUploadedFiles();
  }, []);

  useEffect(() => {
    return () => {
      uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  });

  const getUploadedFiles = useCallback(() => {
    UploadService.get(email)
      .then((response) => {
        const files: IResult[] = response.data as IResult[];

        const result: IFile[] = files.map((image): IFile => {
          return {
            id: image._id,
            name: image.name,
            preview: image.url,
            url: image.url,
            size: filesize(image.size),
            file: null,
            uploaded: true,
            producer: image.producer,
            hasAnnotation: image.totalAnnotations > 0,
            longitude: image.longitude,
            latitude: image.latitude,
            property: image.property
          };
        });

        setUploadedFiles(result);
      })
      .catch(() => {
        return {};
      });
  }, [setUploadedFiles]);

  const updateFile = useCallback((id: string, data) => {
    setFilesToUpload((state) =>
      state.map((file) => (file.id === id ? { ...file, ...data } : file))
    );
  }, []);

  const processFiles = useCallback(
    (values: FormikValues, uploadType: UploadType) => {
      filesToUpload.map((file) => {
        if (!file.uploaded) processUpload(file, values, uploadType);
      });
    },
    [filesToUpload]
  );

  const processAnnotationUpload = async (
    uploadedFile: IFile,
    values: FormikValues,
    updateFile: (id: string, data: unknown) => void
  ) => {
    const {
      producer,
      property,
      crop,
      plot,
      lineSpacing,
      category,
      population
    } = values;
    const data = new FormData();

    data.append("user", email);
    data.append("latitude", uploadedFile.latitude.toString());
    data.append("longitude", uploadedFile.longitude.toString());
    data.append("producer", producer.toLowerCase());
    data.append("property", property.toLowerCase());
    data.append("crop", crop);
    data.append("plot", plot.toLowerCase());
    data.append("lineSpacing", lineSpacing);
    data.append("category", category);
    data.append("population", population);

    const hash = await getHash(
      `${uploadedFile.name}${uploadedFile.latitude}${uploadedFile.longitude}`
    );

    if (hash) data.append("hash", hash);

    if (uploadedFile.file) {
      data.append("file", uploadedFile.file, uploadedFile.name);
    }

    try {
      const response = await UploadService.uploadImagestoAnnotate(data, (e) => {
        const progress = Math.round((e.loaded * 100) / e.total);

        updateFile(uploadedFile.id, {
          progress
        });
      });

      const { _id, url } = response as IResult;

      console.info(
        `A imagem ${uploadedFile.name} foi enviada para o servidor!`
      );

      updateFile(uploadedFile.id, {
        uploaded: true,
        id: _id,
        url: url
      });
    } catch (err) {
      console.error(
        `Houve um problema para fazer upload da imagem ${uploadedFile.name} no servidor`
      );
      console.error(err);

      updateFile(uploadedFile.id, {
        error: err
      });
    }
  };

  const processUpload = useCallback(
    async (
      uploadedFile: IFile,
      values: FormikValues,
      uploadType: UploadType
    ) => {
      if (uploadType === UploadType.Annotation)
        processAnnotationUpload(uploadedFile, values, updateFile);
    },
    [updateFile]
  );

  const handleUpload = useCallback(async (files: File[]) => {
    setLoadingFiles(true);

    const newUploadedFiles: IFile[] = await Promise.all(
      _.map(files, async (file: File) => {
        const path = URL.createObjectURL(file);

        let latitude = 0,
          longitude = 0;

        const gps = await exifr.gps(path);

        if (gps) {
          latitude = gps.latitude;
          longitude = gps.longitude;
        }

        return {
          file: file,
          id: uuidv4(),
          name: file.name,
          size: filesize(file.size),
          preview: path,
          progress: 0,
          uploaded: false,
          url: "",
          latitude: latitude,
          longitude: longitude,
          producer: "",
          property: "",
          category: ""
        };
      })
    );

    setFilesToUpload(newUploadedFiles);
    setLoadingFiles(false);
  }, []);

  const deleteFile = useCallback((id: string) => {
    UploadService.remove(id);
    setUploadedFiles((state) => state.filter((file) => file.id !== id));
  }, []);

  const getHash = async (value) => {
    const result = await sha1(value);
    return result;
  };

  return (
    <FileContext.Provider
      value={{
        isLoadingFiles,
        uploadedFiles,
        filesToUpload,
        handleUpload,
        processUpload,
        deleteFile,
        processFiles,
        getUploadedFiles
      }}
    >
      {children}
    </FileContext.Provider>
  );
};

function useFiles(): IFileContextData {
  const context = useContext(FileContext);

  if (!context) {
    throw new Error("useFiles must be used within FileProvider");
  }

  return context;
}

export { FileProvider, useFiles };
