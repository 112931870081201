import { AuthenticationGuard } from "components/Authentication/authenticationGuard";
import Callback from "features/Callback";
import Home from "features/Home";
import ImagesUpload from "features/Images/Annotation/Upload";
import ImagesAnnotation from "features/Images/Annotation/Viewer";
import InferenceCheck from "features/Images/Inference/Check";
import React from "react";
import { Route, Routes } from "react-router-dom";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="*" element={<Home />} />
      <Route path="/callback" element={<Callback />} />
      <Route
        path="/images/annotation/viewer"
        element={<AuthenticationGuard component={ImagesAnnotation} />}
      />
      <Route
        path="/images/annotation/upload"
        element={<AuthenticationGuard component={ImagesUpload} />}
      />
      <Route
        path="/images/inference/check"
        element={<AuthenticationGuard component={InferenceCheck} />}
      />
    </Routes>
  );
};
export default Router;
