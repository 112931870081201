import AcUnitIcon from "@mui/icons-material/AcUnit";
import CheckIcon from "@mui/icons-material/Check";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ListItemText } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";
import { Link } from "react-router-dom";

const Menu: React.FC = () => {
  return (
    <div>
      <List subheader={<ListSubheader>Imagens</ListSubheader>}>
        <ListItemButton component={Link} to={"/images/annotation/upload"}>
          <ListItemIcon>
            <CloudUploadIcon />
          </ListItemIcon>
          <ListItemText primary="Upload anotação" />
        </ListItemButton>
        <ListItemButton component={Link} to={"/images/annotation/viewer"}>
          <ListItemIcon>
            <AcUnitIcon />
          </ListItemIcon>
          <ListItemText primary="Anotação" />
        </ListItemButton>
        <ListItemButton component={Link} to={"/images/inference/check"}>
          <ListItemIcon>
            <CheckIcon />
          </ListItemIcon>
          <ListItemText primary="check inferência" />
        </ListItemButton>
      </List>
    </div>
  );
};

export default Menu;
