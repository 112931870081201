const config = {
  AUTH: {
    DOMAIN: process.env.REACT_APP_DOMAIN,
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
    CALLBACK_URL: process.env.REACT_APP_CALLBACK_URL
  },
  GAIA_API: {
    URL: process.env.REACT_APP_GA_GAIA_API_URL
  }
};

export default config;
