import { combineReducers } from "redux";
import annotateReducer from "features/Images/Annotation/Viewer/annotateSlice";

const RootReducers = combineReducers({
  Annotate: annotateReducer,
});

export type RootState = ReturnType<typeof RootReducers>;

export default RootReducers;
