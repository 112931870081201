import React from "react";
import { IFile } from "../../services/image";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import ImageIcon from "@mui/icons-material/Image";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import { red } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { makeStyles, withStyles } from "@mui/styles";

const useStyles = makeStyles({
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "&:hover": {
      content: '""',
      zIndex: 1
    }
  },
  block: {
    display: "block"
  }
});

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11
  },
  arrow: {
    color: "#fff"
  }
}))(Tooltip);

interface IMarker {
  file: IFile;
  lat: number;
  lng: number;
}
const Marker: React.FC<IMarker> = ({ file }) => {
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <CustomToolTip
        title={
          <Box
            bgcolor="white"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box width={40} height={40}>
              <Link target="_blank" href={file.preview}>
                <Avatar src={file.preview} variant="rounded">
                  <ImageIcon />
                </Avatar>
              </Link>
            </Box>
            <Box padding={1}>
              {file.producer}
              <br />
              {file.property}
            </Box>
          </Box>
        }
        placement="top"
        arrow
      >
        <LocationOnIcon style={{ color: red[800] }} />
      </CustomToolTip>
    </Box>
  );
};

export default Marker;
