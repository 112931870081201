import axios, { AxiosResponse, Method } from "axios";

interface HeaderInterface {
  "Content-Type": string;
}

interface OptionsInterface {
  method: Method;
  headers?: HeaderInterface;
  data?: unknown | null;
  timeout?: number | 1000;

  params?: unknown | null;
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
}

class ApiManager {
  url: string;
  options: OptionsInterface;

  constructor(url: string, options: OptionsInterface) {
    this.url = url;
    this.options = options;
  }

  async request(): Promise<AxiosResponse<unknown>> {
    const config = {
      url: this.url,
      ...this.options
    };

    if (this.options.data) {
      config.data = this.options.data;
    }

    return axios(config).then((response) => {
      return response;
    });
  }
  async fetchData(): Promise<unknown> {
    return new Promise((resolve) => {
      return this.request().then((response) => {
        setTimeout(() => {
          if (response.status >= 300) {
            return resolve([true, response]);
          }
          return resolve([false, response]);
        }, 300);
      });
    }).catch((err) => console.log(err));
  }
}

export type { HeaderInterface, OptionsInterface };

export { ApiManager };
