import React from "react";
import Layout from "components/Layout";
import Typography from "@mui/material/Typography";

const Home: React.FC = () => {
  return (
    <Layout>
      <Typography variant="h5" align="center">
        Bem-vindo ao GAIA
      </Typography>
    </Layout>
  );
};

export default Home;
