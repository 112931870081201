import React, { useState, useEffect } from "react";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImageIcon from "@mui/icons-material/Image";
import Tooltip from "@mui/material/Tooltip";
import Link from "@mui/material/Link";
import CircularProgress, {
  CircularProgressProps
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Divider from "@mui/material/Divider";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotesIcon from "@mui/icons-material/Notes";
import { green } from "@mui/material/colors";
import { makeStyles, withStyles } from "@mui/styles";

import { useFiles } from "../../context/files";
import { IFile } from "../../services/image";

const useStyles = makeStyles({
  root: {
    width: "100%",
    backgroundColor: "white",
    padding: "2px"
  },
  block: {
    display: "block"
  },
  paginator: {
    justifyContent: "center",
    padding: "10px"
  }
});

const ErrorToolTip = withStyles({
  tooltip: {
    color: "white",
    backgroundColor: "red"
  }
})(Tooltip);

interface FileListProps {
  files: IFile[];
  type: string;
}

const FileList: React.FC<FileListProps> = ({ files, type }) => {
  const classes = useStyles();
  const itemsPerPage = 10;
  const [page, setPage] = useState(1);

  const [totalPages, setTotalPages] = useState(0);
  const { deleteFile } = useFiles();

  const message = "Nenhuma imagem disponível";

  useEffect(() => {
    setTotalPages(Math.ceil(files.length / itemsPerPage));
  }, [files, itemsPerPage]);

  const handleChange = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleDelete = (id: string) => {
    files = files.filter((file) => file.id !== id);
    deleteFile(id);

    //update page
    const newTotalPages = Math.ceil(files.length / itemsPerPage);
    if (totalPages > newTotalPages && page === totalPages) {
      setPage(page - 1);
    }
  };

  return (
    <>
      {!files.length && type === "uploadedFiles" && <span>{message}</span>}
      {files.length > 0 && (
        <>
          <Typography
            component="h1"
            variant="subtitle1"
            align="left"
            style={{ marginTop: 10 }}
          >
            Total de imagens: {files.length}
          </Typography>
          <Box className={classes.root}>
            <List>
              {files
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map((file) => {
                  return (
                    <ListItem key={file.id}>
                      <ListItemAvatar>
                        <Link target="_blank" href={file.preview}>
                          <Tooltip title="Abrir" placement="left" arrow>
                            <Avatar src={file.preview} variant="rounded">
                              <ImageIcon />
                            </Avatar>
                          </Tooltip>
                        </Link>
                      </ListItemAvatar>
                      <ListItemText
                        id={file.name}
                        primary={file.name}
                        secondary={
                          <Typography
                            component="span"
                            variant="caption"
                            className={classes.block}
                            color="textPrimary"
                          >
                            {"Tamanho: " + file.size}
                          </Typography>
                        }
                      />
                      <Box>
                        <CircularProgressWithLabel value={file.progress ?? 0} />
                      </Box>

                      {file.error && (
                        <ErrorToolTip
                          title={"Erro: " + file.error.message}
                          placement="left"
                        >
                          <ErrorIcon style={{ color: "red" }} />
                        </ErrorToolTip>
                      )}
                      {!file.hasAnnotation && type === "uploadedFiles" && (
                        <ListItemSecondaryAction>
                          <Tooltip title="Remover" placement="right" arrow>
                            <IconButton
                              edge="end"
                              aria-label="Remover"
                              onClick={() => handleDelete(file.id)}
                            >
                              <DeleteForeverIcon />
                            </IconButton>
                          </Tooltip>
                        </ListItemSecondaryAction>
                      )}
                      {file.hasAnnotation && (
                        <Tooltip
                          title="Essa imagem contém anotação"
                          placement="right"
                          arrow
                        >
                          <NotesIcon />
                        </Tooltip>
                      )}
                    </ListItem>
                  );
                })}
            </List>

            {totalPages > 1 && (
              <>
                <Divider />
                <Box component="span">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChange}
                    defaultPage={1}
                    color="primary"
                    size="medium"
                    showFirstButton
                    showLastButton
                    classes={{ ul: classes.paginator }}
                    shape="rounded"
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};

const CircularProgressWithLabel = (
  props: CircularProgressProps & { value: number }
) => {
  if (props.value > -1 && props.value < 100) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" {...props} color={"primary"} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color="secondary"
        >
          {props.value > 0 ? (
            <Typography
              variant="caption"
              component="span"
              color="textSecondary"
            >{`${Math.round(props.value)}%`}</Typography>
          ) : (
            ""
          )}
        </Box>
      </Box>
    );
  }

  if (props.value === 100)
    return (
      <Tooltip title="Imagem enviada com sucesso" placement="left" arrow>
        <CheckCircleIcon style={{ color: green[500], marginTop: 3 }} />
      </Tooltip>
    );

  return <></>;
};

export default FileList;
