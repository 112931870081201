import { Box, Button } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar
} from "@mui/x-data-grid";
import { ICheckListInferences } from "features/Images/Inference/Check/types";
import React, { useState } from "react";
import FullScreenDialog from "./dialog";
import { IDataTableCheckInferences } from "./types";

const valueFormat = (v) => {
  const valor = v.value as number;
  if (valor) {
    return `${valor.toFixed(2)} %`;
  }

  return v.value;
};

export const DataTableCheckInferences: React.FC<IDataTableCheckInferences> = ({
  rows
}) => {
  const [urlImage, setUrlImage] = useState<string | undefined>();
  const [data, setData] = useState<ICheckListInferences>();

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const columns: GridColDef[] = [
    {
      field: "id_imagem",
      headerName: "Id Imagem",
      width: 100
    },
    {
      field: "produtor",
      headerName: "Produtor",
      width: 100
    },
    {
      field: "propriedade",
      headerName: "Propriedade",
      width: 150
    },
    {
      field: "cultura",
      headerName: "Cultura",
      width: 100,
      valueFormatter: (col) => {
        switch (col.value) {
          case "soy":
            return "soja";
          case "corn":
            return "milho";
          default:
            return "algodão";
        }
      }
    },
    {
      field: "talhao",
      headerName: "Talhão",
      width: 100
    },
    {
      field: "populacao",
      headerName: "População",
      width: 100
    },
    {
      field: "plantas_estimadas",
      headerName: "ne",
      width: 50
    },
    {
      field: "plantas_observadas",
      headerName: "no",
      width: 100
    },
    {
      field: "no_falhas",
      headerName: "nf",
      width: 100,
      cellClassName: "super-app-theme--header"
    },
    {
      field: "perc_falhas_manual",
      headerName: "%f (m)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "perc_falhas_inf",
      headerName: "%f (i)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "no_replicatas",
      headerName: "nr",
      width: 100,
      cellClassName: "super-app-theme--header"
    },
    {
      field: "perc_replicatas_manual",
      headerName: "%r (m)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "perc_replicatas_inf",
      headerName: "%r (i)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "no_atrasadas",
      headerName: "neat",
      width: 100,
      cellClassName: "super-app-theme--header"
    },
    {
      field: "perc_atrasadas_manual",
      headerName: "%at (m)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "perc_atrasadas_inf",
      headerName: "%at (i)",
      valueFormatter: valueFormat,
      flex: 1
    },
    {
      field: "url_image",
      headerName: "Image",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string>) => (
        <strong>
          <Button
            variant="contained"
            size="small"
            tabIndex={params.value ? 0 : -1}
            onClick={() => {
              setUrlImage(params.value);
              setData({
                ...params.row
              });
              setOpenDialog(true);
            }}
          >
            Open
          </Button>
        </strong>
      )
    }
  ];

  return (
    <Box
      sx={{
        width: "100%",
        "& .super-app-theme--header": {
          backgroundColor: "rgba(215, 233, 255, 0.55)"
        }
      }}
    >
      {data && urlImage && (
        <FullScreenDialog
          url={urlImage}
          open={openDialog}
          handleClose={handleClose}
          data={data}
        />
      )}
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row._id}
        autoHeight
        components={{ Toolbar: GridToolbar }}
      />
    </Box>
  );
};

export default DataTableCheckInferences;
