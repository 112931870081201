import React from "react";

import { FormikValues } from "formik";
import { Box, Button } from "@mui/material";
interface IProps {
  isLastStep: boolean;
  isSubmitting: boolean;
  onBackClick: (values: FormikValues) => void;
  hasPrevious?: boolean;
}

const FormNavigation: React.FC<IProps> = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "50px",
        justifyContent: "space-between"
      }}
    >
      <Button
        disabled={!props.hasPrevious}
        variant="contained"
        type="button"
        onClick={props.onBackClick}
      >
        Voltar
      </Button>

      <Button
        type="submit"
        color="primary"
        variant="contained"
        disabled={!props.isSubmitting}
      >
        {props.isLastStep ? "Finalizar" : "Próximo"}
      </Button>
    </Box>
  );
};

export default FormNavigation;
